@import '../../../model/styles/variables';

.marketing-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // justify-content: center;
  padding: 20px;
  margin: 20px 0;
  background-color: $off-white;
  margin: 10px 10px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1);

  &:last-child {
    justify-content: last baseline;
  }

  .marketing-image {
    max-width: 150px;
    margin-right: 20px;
    margin-top: 10px;

    @media (max-width: 582px) {
      display: none;
    }
  }

  .marketing-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    box-sizing: border-box;

    h2 {
      margin: 0;
      font-size: 24px;
    }

    .marketing-content {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      p {
        margin: 10px 0;
        font-size: 16px;
      }
    }
  }

  .button-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;    

    .learn-more-button {
      background-color: transparent;
      color: #0072c6;
      font-size: 16px;
      border: none;
      padding-left: 20px;
      cursor: pointer;
    
      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 582px) {
        padding-left: 0;
      }
    }
  }
}

$form-field-color: #444; // change this to adjust the label color
$form-field-error-color: #ec0e0e;

.form-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  position: relative;
  padding-bottom: 1.8rem;

  label {
    position: absolute;
    top: 22px;
    left: 14px;
    font-size: 1rem;
    color: $form-field-color;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }

  input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    margin-top: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
      /* WebKit browsers */
       color: transparent;
  }
   &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
       color: transparent;
  }
   &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
       color: transparent;
  }
   &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
       color: transparent;
  }
   &::placeholder {
       color: transparent;
  }
  &:-webkit-autofill {
    color: transparent;
  }

    &:focus,
    &:not(:placeholder-shown) {
      outline: none;
      border-color: $form-field-color;

      & + label {
        transform: translateY(-1.8rem);
        font-size: 0.8rem;
        color: $form-field-color;
      }
    }

    // has-error can be added to class name to highlight error field in red
    &.has-error {
      border-color: $form-field-error-color;
      box-shadow: 0 0 5px $form-field-error-color;
      &:focus,
      &:not(:placeholder-shown) {
        border-color: $form-field-error-color;
        box-shadow: 0 0 5px $form-field-error-color;
      }
      & + label {
        color: $form-field-error-color;
      }
    }
  }
}

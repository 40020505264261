.enroll-form {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  
  h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  }

  .button-container {
    width: auto;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 600px) {
  padding: 10px;
  }
  
  @media screen and (max-width: 375px) {
  padding: 0;
  } 
}
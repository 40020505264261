@import '../../../model/styles/variables';

.navigation {
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;

  .hamburger-btn {
    display: none;
    margin-right: 10px;
  }

  .navigation-contents {
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .navigation-menu {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 10px;

        button {
          background: #333;
          font-weight: 600;
          padding: 5px;
          border: none;
          color: #fff;
          cursor: pointer;
        }

        a {
          text-decoration: none;
          color: $font-nav-static;
          font-size: 13px;
          font-weight: bold;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }

    .navigation-toggle {
      display: none;
      background: none;
      border: none;
      color: inherit;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      .navigation-toggle {
        display: block;
      }

      .navigation-menu {
        display: none;
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 100%;
        background-color: #333;
        z-index: 2;

        li {
          margin: 0 30px;
          padding: 30px 20px;
          font-size: 16px;
          border-top: 1px solid $dark-black;

          &:first-child {
            border-top: none;
          }

          a {
            font-size: 16px;
          }
        }

        &.open {
          display: block;
        }
      }

      .hamburger-btn {
        display: block;
      }
    }
  }
}

.payment-form-container {
  // height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout-form-container {
  // height: 380px;
  min-width: 375px;
}

.payment-form-inputs {
  margin: 30px 0;
}
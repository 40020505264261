@import '../../../model/styles/variables';

header {
  display: flex;
  justify-content: center;
  background-color: $dark-black;

  .header-wrapper {
    position: relative;
    width: 100vw;
    max-width: 1200px;
    z-index: 2;

    h1 {
      color: $off-white;
      position: absolute;
      font-size: 20px;
      padding-left: 10px;
    }
  }
}

@import '../../../model/styles/variables';

.banner-img {
  width: 100%;
  height: auto;
  }
  
  .banner-text {
  position: absolute;
  right: 30px;
  bottom: 30px;
  max-width: 280px;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);;
  
  @media (max-width: 767px) {
  position: absolute;
  right: 0px;
  top: 1px;
  max-width: 200px;
  font-size: 0.8rem;
  }
}
  
.marketing-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $off-white;
  padding-top: 10px;
  margin: 50px auto
}

.courses-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $off-white;
  padding: 10px;
  box-sizing: border-box;
  margin: 50px auto
}

@import '../../../model/styles/variables';

.footer {
  background-color: $dark-gray;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  height: 276px;

  .width-restriction {
    width: 100%;
    max-width: 1200px;

    .footer-content-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      color: $off-white;

      .footer-content-group {
        margin: 1rem;
        flex-basis: 25%;

        .footer-content-title {
          margin-bottom: 0.5rem;
        }

        .footer-content {
          color: $font-footer;
        }
      }
    }

    .footer-link-container {
      width: 100%;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 0;

        .footer-link {
          a {
            color: $font-footer-link;
            margin: 0 30px;

            &:hover {
              color: $off-white;
            }
          }
        }
      }
    }

    .footer-copyright {
      display: flex;
      justify-content: center;

      p {
        color: $off-white;
        margin-bottom: 1rem;
      }
    }
  }
}

.catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 20px;

  &__tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    &__tab {
      border: none;
      background: none;
      color: #333;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        color: #444;
      }

      &--active {
        color: #0077c2;
        border-bottom: 2px solid #0077c2;
      }
    }
  }

  &__course {
    width: 100%;
    max-width: 800px;

    &__title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    &__description {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    &__classes {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;

      &__class {
        border: 1px solid #ddd;
        padding: 2rem;

        &-item {
          font-size: 1rem;
          margin-bottom: 1rem;
        }

        &-enroll {
          background-color: #0077c2;
          color: #fff;
          border: none;
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background-color: #005fa3;
          }

          &:disabled {
            background-color: #ddd;
            color: #333;
            cursor: not-allowed;
          }
        }
      }
    }

    &__request-class {
      background-color: #fff;
      color: #0077c2;
      border: 2px solid #0077c2;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: 2rem;
      margin-right: 20px;

      &:hover {
        background-color: #0077c2;
        color: #fff;
      }

      &:disabled {
        background-color: #ddd;
        color: #333;
        cursor: not-allowed;
      }
    }

    &__enter-code {
      background-color: #0077c2;
          color: #fff;
          border: none;
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
      margin-top: 2rem;

      &:hover {
        background-color: #005fa3;
      }

      &:disabled {
        background-color: #ddd;
        color: #333;
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__course {
      &__classes {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
  }

  // @media screen and (max-width: 576px) {
  //   &__course {
  //     &__classes {
  //       grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  //     }
  //   }
  // }
}

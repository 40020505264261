@import '../../../model/styles/variables';

.custom-button {
  background-color: $button-black;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 180px;
  margin: 20px 0;
  align-items: center;
  &:hover {
    background-color: $dark-gray;
  }
}

.blue-link-button {
  border: none;
  background-color: transparent;
  color: #0072c6;
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.model-close {
  position: absolute;
  background: none;
  border: none;
  top: 40px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.button-spinner-container {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}

@import '../../../model/styles/variables';

.horizontial-rule {
  font-family: "Share Tech Mono", monospace;
  color: $dark-gray;
  font-size: 2vh;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .45);
  
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 50px auto;
  
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0.09em;
    min-width: 20vw;
  }
  
  &::before {
    background: linear-gradient(to right, rgba(63, 63, 67, 0), $dark-gray);
    margin-right: 4vh;
  }
  
  &::after {
    background: linear-gradient(to left, rgba(63, 63, 67, 0), $dark-gray);
    margin-left: 4vh;
  } 
}
.message {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 5px;

  p {
    margin: 0;
    padding-left: 10px;
  }

  &.general {
    color: #2f80ed;
    background-color: #d0e7ff;
    .icon {
      color: #2f80ed;
    }
  }

  &.error {
    color: #d93025;
    background-color: #ffe0e0;
    .icon {
      color: #d93025;
    }
  }

  &.info {
    color: #008000;
    background-color: #d0ffd0;
    .icon {
      color: #008000;
    }
  }

  &.warning {
    color: #ffa500;
    background-color: #fff0d0;
    .icon {
      color: #ffa500;
    }
  }
}

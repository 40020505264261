.optional-checkout-group {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  min-width: 200px;

  input {
    margin-right: 10px;
  }
}
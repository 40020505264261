@import '../../../model/styles/variables';

.marketing-content-container {
  background-color: $off-white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  max-width: 300px;

  @media (max-width: 582px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 10px 25px;
  }

  &.expanded {
    max-width: 850px;
    box-sizing: border-box;
    position: absolute;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    margin: 10px;
    background-color: #fff;
  }

  .marketing-content-header {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .marketing-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    margin-bottom: 10px;

    .marketing-content-image {
      max-width: 100%;
      max-height: 200px;
      border-radius: 20px;
    }
  }

  .marketing-content-text {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: calc(1.5 * 4 * 16px);

    &-expanded {
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      margin-bottom: 20px;
      max-height: none;
    }
  }

  .read-more-button {
    border: none;
    background-color: transparent;
    color: #0072c6;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100VW;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* This makes the overlay semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
}

.model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  z-index: 999; /* Make sure it's on top of everything */
}

.hidden {
  display: none;
}

.widget-model {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center using transform */
  z-index: 1000; /* Make sure it's on top of the overlay */
  /* Add other styles for the modal content */
}

.widget {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  
  h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  }

  .button-container {
    width: auto;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 600px) {
  padding: 10px;
  }
  
  @media screen and (max-width: 375px) {
  padding: 10px;
  }

  .error-message {
    background-color: #f8d7da; /* light red */
    border: 1px solid #f5c6cb; /* dark red */
    border-radius: 4px;
    color: #721c24; /* dark red */
    font-size: 14px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
  }  
}
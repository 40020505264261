$dark-black: #0F1014;
$dark-gray: #2A292E;
$off-white: #F8F8FF;

$button-black: #0E0F13;
$button-secondary: #D4D3D2;

$font-footer: #D4D3D3;
$font-footer-link: #575657;
$font-nav-static: #9B9B9C;
$font-title-dark: #151618;
$font-title-light: #B6B8B8;